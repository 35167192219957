import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Index'
import Style from '../styles/pages/contact.module.scss'

class Thanks extends React.Component {

  render() {
    const {
      data: { 
        thanksPage: { 
          data: { content }
        },
        staticText: { edges }
      }
    } = this.props;

    const staticPrismicTexts = edges[0].node.data.texts;
    return (
      <Layout context={this.props.pageContext} _t={staticPrismicTexts}>
        <div className={`${Style.container} page"`}>
          <section style={{
            paddingTop: '10rem',
            height: 'calc(100vh - 590px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <div className="container">
              <div className={`${Style.row} row`}>
                <div 
                  className="col-12" 
                  style={{
                    textAlign: 'center'
                  }}
                  dangerouslySetInnerHTML={{
                  __html: content.html
                }}></div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  };
};

export default Thanks;

export const pageQuery = graphql`
  query thanksPage($locale: String!) {
    thanksPage: prismicAccountCreatedThanks(lang: {eq: $locale}) {
      data {
        content {
          html
        }
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;